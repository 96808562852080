import React, { useState, useEffect } from 'react';
import FormModal from './components/FormModal';
import firebase from "./firebase";

const App = () => {
  const initialFieldValues = {
    searchValue: '',
  }

  var [values, setValues] = useState(initialFieldValues)
  var [currentId, setCurrentId] = useState('');
  var [reportObjects, setreportObjects] = useState({})
  var search = false;

  //Once components load complete
  useEffect(() => {
    firebase.child('reports').on('value', snapshot => {
      if (snapshot.val() != null) {
        setreportObjects({
          ...snapshot.val()
        });
      }
    })
  }, [])

  const handleInputChange = e => {
    e.preventDefault()
    setValues({
      ...values,
      searchValue: e.target.value
    });
  }

  const handleSearch = e => {
    e.preventDefault();
    console.log("searching")
    firebase.child('reports').orderByChild("bikeNum").equalTo(values.searchValue).on("value", function (snapshot) {
      if (snapshot.val() != null) {
        // Get all results for that bike
        setreportObjects({
          ...snapshot.val()
        });
      } else {
        // Get all results
        firebase.child('reports').on('value', snapshot => {
          if (snapshot.val() != null) {
            setreportObjects({
              ...snapshot.val()
            });
          }
        })
      }
      console.log("NO RESULTS")
      return (<div> NO RESULTS FOUND</div>);
    });
  }

  const addOrEdit = (obj) => {
    if (currentId === '') {
      firebase.child('reports').push(
        obj,
        err => {
          if (err)
            console.log(err)
          else
            setCurrentId('')
        });
      window.$("#addModal").modal('toggle');
    }
    else
      firebase.child(`reports/${currentId}`).set(
        obj,
        err => {
          if (err)
            console.log(err)
          else
            setCurrentId('')
        })
  }

  const onDelete = id => {
    if (window.confirm('Are you sure to delete this record?')) {
      firebase.child(`reports/${id}`).remove(
        err => {
          if (err)
            console.log(err)
          else
            setCurrentId('')
        })
    }
  }

  return (
    <div>
      <div className="jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="display-4 text-center">EVie Bike Reports</h1>
        </div>
      </div>
      <div className="container text-center">
        <div className="row">
          <div className="column">
            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#addModal">
              Add new report</button>
          </div>

          <div className="column" style={{ marginLeft: 'auto', marginRight: 0 }}>
            <form onSubmit={handleSearch}>
              <input onChange={handleInputChange} value={values.searchValue} type="number" id="searchBox" placeholder="Search by bike number"></input>
              <button type="submit" className="btn"><i className="fa fa-search"></i></button>
            </form>
          </div>
        </div>
        <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
          <FormModal {...({ currentId, reportObjects, addOrEdit })} />
        </div>
        <br />

        <div className="row">
          <div className="col-sm-12">
            <table className="table table-borderless table-stripped">
              <thead className="thead-light">
                <tr>
                  <th>Bike Number</th>
                  <th>Reason for Visit</th>
                  <th>Date</th>
                  <th>Mechanic</th>
                  <th>Parts Used</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(reportObjects).map((key) => (
                    <tr key={key}>
                      <td>{reportObjects[key].bikeNum}</td>
                      <td>{reportObjects[key].reason}</td>
                      <td>{reportObjects[key].date}</td>
                      <td>{reportObjects[key].mechanic}</td>
                      <td>{reportObjects[key].partsUsed}</td>
                      <td>{reportObjects[key].notes}</td>
                      <td className="bg-light">
                        <div className="btn text-primary" onClick={() => { setCurrentId(key) }}>
                          <i className="fas fa-pencil-alt" data-toggle="modal" data-target="#editModal"   ></i>
                          <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
                            <FormModal {...({ currentId, reportObjects, addOrEdit })} />
                          </div>
                        </div>
                        <div className="btn text-danger" onClick={() => { onDelete(key) }}>
                          <i className="far fa-trash-alt"></i>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div >
  );
}

export default App;