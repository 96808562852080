import * as firebase from "firebase";

var firebaseConfig = {
    apiKey: "AIzaSyAdHV6xRf2dhs08by__wFL9p7r_jBOQudU",
    authDomain: "evie-report.firebaseapp.com",
    databaseURL: "https://evie-report.firebaseio.com",
    projectId: "evie-report",
    storageBucket: "evie-report.appspot.com",
    messagingSenderId: "1014191267318",
    appId: "1:1014191267318:web:8735a6ab289c0d965d7bd7",
    measurementId: "G-M7C9YXPXTF"
  };
  
// Initialize Firebase
var fireDb = firebase.initializeApp(firebaseConfig);

export default fireDb.database().ref();