import React, { useState, useEffect } from 'react';
import firebaseDb from "../firebase";
import $ from 'jquery';

const FormModal = (props) => {
    var [currentId, setCurrentId] = useState('');
    var [reportObjects, setreportObjects] = useState({});

    //Once components load complete
    useEffect(() => {
        firebaseDb.child('reports').on('value', snapshot => {
            if (snapshot.val() != null) {
                setreportObjects({
                    ...snapshot.val()
                });
            }
        })
    }, []);

    const initialFieldValues = {
        bikeNum: '',
        mechanic: '',
        date: '',
        reason: '',
        notes: '',
        partsUsed: '',
        hasGusset: '',
    }

    var [values, setValues] = useState(initialFieldValues)
    var showingTextbox = false;
    var showingPartsBox = false;
    var parts = ["Front wheel", "Rear wheel", "PAS sensor", "Crank set", "Chain (155cm)", "Pedals", "Kickstand", "Brake caliper (pc)", "Brake pads", "Seat post", "Seat post clamp", "Saddle", "Bell", "Inner tube", "Tyre", "Chain tensioner"];
    var reasons = ["Electrical", "Chain", "Puncture", "Pedal", "Brakes"]

    useEffect(() => {
        if (props.currentId === '')
            setValues({ ...initialFieldValues })
        else
            setValues({
                ...props.reportObjects[props.currentId]
            })
    }, [props.currentId, props.reportObjects])

    const handleInputChange = e => {
        var { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });

        // Check if 'Other' option is set for 'Reason for visit'
        var box = document.getElementById('otherTextbox');
        if (name === "reason" && !reasons.includes(value)) {
            box.style.display = 'block';
            showingTextbox = true;
        }
        if (name === "reason" && reasons.includes(value)) {
            box.style.display = 'none';
            showingTextbox = false;
        }

        // Check if 'Other' option is set for 'Parts Used'
        var partsBox = document.getElementById('partsTextbox');
        if (name === "partsUsed" && !parts.includes(value)) {
            partsBox.style.display = 'block';
            showingPartsBox = true;
        }
        if (name === "partsUsed" && parts.includes(value)) {
            partsBox.style.display = 'none';
            showingPartsBox = false;
        }
    }

    const handleFormSubmit = e => {
        e.preventDefault()
        props.addOrEdit(values);
    }

    function loadGusset() {
        if (props.currentId !== "" && values.hasGusset === "false") {
            return (
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" id="with" name="hasGusset" value="true"
                            onChange={handleInputChange}
                            required></input>
                        <label className="form-check-label" for="with">Frame with gusset</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" id="without" name="hasGusset" value="false"
                            onChange={handleInputChange}
                            checked
                            required></input>
                        <label className="form-check-label" for="without">Without gusset</label>
                    </div>
                </div>
            );
        } if (props.currentId !== "" && values.hasGusset === "true") {
            return (
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" id="with" name="hasGusset" value="true"
                            onChange={handleInputChange}
                            checked
                            required></input>
                        <label className="form-check-label" for="with">Frame with gusset</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" id="without" name="hasGusset" value="false"
                            onChange={handleInputChange}
                            required></input>
                        <label className="form-check-label" for="without">Without gusset</label>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" id="with" name="hasGusset" value="true"
                            onChange={handleInputChange}
                        ></input>
                        <label className="form-check-label" for="with">Frame with gusset</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" id="without" name="hasGusset" value="false"
                            onChange={handleInputChange}
                        ></input>
                        <label className="form-check-label" for="without">Without gusset</label>
                    </div>
                </div>
            );
        }
    }

    function loadReason() {
        if (showingTextbox || !reasons.includes(values.reason)) {
            return (
                <div>
                    <select className="form-control" type="text" name="reason" id="reason"
                        value={values.reason}
                        onChange={handleInputChange}
                        required>
                        <option value="" disabled>-</option>
                        <option>Other</option>
                        <option>Electrical</option>
                        <option>Pedal</option>
                        <option>Chain</option>
                        <option>Puncture</option>
                        <option>Brakes</option>
                        <option>Wheel</option>
                    </select>
                    <input className="form-control" type="text" name="reason" id="otherTextbox"
                        value={values.reason}
                        onChange={handleInputChange}
                        required
                        style={{ display: 'block' }}
                    ></input>
                </div>
            );
        } else {
            return (
                <div>
                    <select className="form-control" type="text" name="reason" id="reason"
                        value={values.reason}
                        onChange={handleInputChange}
                        required>
                        <option value="" disabled>-</option>
                        <option>Other</option>
                        <option>Electrical</option>
                        <option>Pedal</option>
                        <option>Chain</option>
                        <option>Puncture</option>
                        <option>Brakes</option>
                        <option>Wheel</option>
                    </select>
                    <input className="form-control" type="text" name="reason" id="otherTextbox"
                        value={values.reason}
                        onChange={handleInputChange}
                        required
                        style={{ display: 'none' }}
                    ></input>
                </div>
            );
        }
    }

    function loadParts() {
        if (showingPartsBox || !parts.includes(values.partsUsed)) {
            return (
                <div>
                    <select className="form-control" type="text" name="partsUsed" id="partsUsed"
                        value={values.partsUsed}
                        onChange={handleInputChange}
                    >
                        <option value="" disabled>-</option>
                        <option>Other</option>
                        {parts.map(part => {
                            return <option value={part}>
                                {part}
                            </option>
                        })}
                    </select>
                    <input className="form-control" type="text" name="partsUsed" id="partsTextbox"
                        value={values.partsUsed}
                        onChange={handleInputChange}
                        style={{ display: 'block' }}
                    ></input>
                </div>
            );
        } else {
            return (
                <div>
                    <select className="form-control" type="text" name="partsUsed" id="partsUsed"
                        value={values.partsUsed}
                        onChange={handleInputChange}
                    >
                        <option value="" disabled>-</option>
                        <option>Other</option>
                        {parts.map(part => {
                            return <option value={part}>
                                {part}
                            </option>
                        })}
                    </select>
                    <input className="form-control" type="text" name="partsUsed" id="partsTextbox"
                        value={values.partsUsed}
                        onChange={handleInputChange}
                        style={{ display: 'none' }}
                    ></input>
                </div>
            );
        }
    }

    return (
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add new report</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form autoComplete="off" onSubmit={handleFormSubmit}>
                        <div className="form-group input-group">
                            <input className="form-control" name="bikeNum" type="number" placeholder="Bike Number"
                                value={values.bikeNum}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-group col-sm">
                                <label for="mechanic">Mechanic</label>
                                <select className="form-control" type="text" name="mechanic" id="mechanic" placeholder={values.mechanic}
                                    value={values.mechanic}
                                    onChange={handleInputChange}
                                    required>
                                    <option value="" disabled>-</option>
                                    <option>Bolbalaz</option>
                                    <option>Ross</option>
                                    <option>Cristiano</option>
                                    <option>Lucas</option>
                                    <option>Cieran</option>
                                    <option>Nick</option>
                                    <option>Andrew</option>
                                    <option>Dillon C</option>
                                </select>
                            </div>

                            <div className="form-group col-sm">
                                <label for="date">Date of Visit</label>
                                <input className="form-control" name="date" type="date" placeholder="Date of visit"
                                    value={values.date}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label for="reason">Reason for Visit</label>
                            {loadReason()}
                        </div>
                        <div className="form-group">
                            <label for="partsUsed">Parts Used</label>
                            {loadParts()}
                        </div>

                        <div className="form-group">
                            <textarea className="form-control" type="text" id="notes" name="notes" placeholder="Extra notes"
                                value={values.notes}
                                onChange={handleInputChange}
                            ></textarea>
                        </div>

                        {loadGusset()}

                        <div className="form-group">
                            <input type="submit" value={props.currentId === "" ? "Save" : "Update"} className="btn btn-primary btn-block" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default FormModal;